.accordion {
  border-bottom: 1px solid #535961;
  &-custom {
    li {
      display: flex;
      align-items: center;
      gap: 10px;
      i {
        font-size: 7px;
      }
    }
  }
  &-title {
    @media screen and (max-width: 700px) {
      line-height: 22px;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 30px 0;
  }
  &-btn {
    background-color: transparent;
    border: 0;
  }
  &-icon {
    width: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: 700px) {
      width: 12px;
    }
  }
  &-icon-active {
    transform: rotate(90deg);
  }
  &-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    &-content {
      padding: 0 0 40px;
      line-height: 25px;
      border-top: 0;
      // color: #b0caff;
    }
  }
  &-body-active {
    transition: max-height 0.3s ease-in;
    max-height: 600px;
  }
}
