#section-top-video {
  width: 100%;
  height: 100%;
}

.btn-bot {
  margin-left: 20px;
}

// Header
.header {
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 700px) {
    padding: 32px 0;
  }
  &-right {
    display: flex;
    gap: 20px;
  }
  &-nav {
    display: none;
    position: relative;
    z-index: 99999;
    font-size: 30px;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
      display: inline-block;
    }
  }
  &-menu {
    display: flex;
    align-items: center;
    gap: 50px;
    @media screen and (max-width: 1350px) {
      gap: 40px;
    }
    @media screen and (max-width: 1200px) {
      gap: 30px;
    }
    @media screen and (max-width: 1000px) {
      gap: 20px;
      display: none;
    }
    &-mb {
      display: inline-flex;
      // gap: 20px;
      flex-direction: column;
      position: absolute;
      z-index: 99999;
      left: -100%;
      width: 30%;
      height: 100vh;
      padding: 100px 50px 30px;
      background-color: rgba(4, 24, 47);
      font-size: 17px;
      transition: all 0.3s ease-in;
      &.active {
        left: 0;
      }
      li {
        margin-bottom: 20px;
      }
    }
  }
}

.menu-logo {
  width: 200px;
  @media screen and (max-width: 1000px) {
    width: 150px;
  }
}

// Intro
.intro {
  margin-top: 100px;
  display: flex;
  min-height: 500px;
  @media screen and (max-width: 1500px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 700px) {
    margin-top: 0;
  }
  &-left {
    width: 40%;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
    p {
      margin-bottom: 40px;
      @media screen and (max-width: 700px) {
        margin-bottom: 20px;
      }
    }
  }
  &-right {
    width: 60%;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  &-title {
    margin: 40px 0;
    font-size: 4rem;
    @media screen and (max-width: 1800px) {
      font-size: 3.8rem;
    }
    @media screen and (max-width: 1600px) {
      font-size: 3.5rem;
    }
    @media screen and (max-width: 1000px) {
      font-size: 3rem;
      margin: 30px 0;
    }
    @media screen and (max-width: 700px) {
      font-size: 2.5rem;
      margin: 15px 0;
    }
  }
  &-img {
    position: absolute;
    top: 22%;
    right: 18%;
    @media screen and (max-width: 2050px) {
      width: 600px;
    }

    @media screen and (max-width: 1750px) {
      width: 530px;
    }
    @media screen and (max-width: 1680px) {
      top: 22%;
    }
    @media screen and (max-width: 1600px) {
      width: 480px;
    }
    @media screen and (max-width: 1480px) {
      width: 450px;
      right: 18%;
      top: 22%;
    }
    @media screen and (max-width: 1380px) {
      width: 440px;
    }
    @media screen and (max-width: 1350px) {
      width: 420px;
      right: 17%;
      top: 20%;
    }
    @media screen and (max-width: 1280px) {
      width: 400px;
      right: 18%;
      top: 21%;
    }
    @media screen and (max-width: 1250px) {
      width: 380px;
    }
    @media screen and (max-width: 1150px) {
      width: 300px;
      right: 20%;
      top: 26%;
    }
    @media screen and (max-width: 1050px) {
      right: 19%;
      top: 24%;
    }
  }
}
