// About
.about {
  margin-top: -100px;
  position: relative;
  z-index: 999999;
  overflow: hidden;
  @media screen and (max-width: 1650px) {
    margin-top: -30px;
  }
  @media screen and (max-width: 1500px) {
    margin-top: 50px;
  }
  @media screen and (max-width: 1000px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 700px) {
    margin-top: 140px;
  }
  &-bg {
    position: absolute;
    right: -80px;
    top: 280px;
    z-index: 1;
    height: 900px;
    @media screen and (max-width: 1000px) {
      height: 600px;
      top: 700px;
    }
    @media screen and (max-width: 700px) {
      // display: none;
      height: 500px;
      top: 600px;
    }
  }
  &-title {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 1100px) {
      width: 85%;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
    }
    h5 {
      line-height: 40px;
      margin-top: 30px;
      margin-bottom: 20px;
      @media screen and (max-width: 1500px) {
        line-height: 35px;
      }
      @media screen and (max-width: 1000px) {
        line-height: 30px;
      }
      @media screen and (max-width: 700px) {
        line-height: 22px;
      }
    }
  }
  &-main {
    display: flex;
    margin-top: 100px;
    align-items: center;
    gap: 70px;
    @media screen and (max-width: 1400px) {
      gap: 50px;
    }
    @media screen and (max-width: 1300px) {
      gap: 30px;
    }
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      margin-top: 70px;
    }
    @media screen and (max-width: 700px) {
      margin-top: 50px;
    }
    &-right {
      flex: 1;
      position: relative;
      z-index: 88;
    }
    &-left {
      position: relative;
      z-index: 88;
    }
    &-img {
      width: 450px;
      @media screen and (max-width: 1400px) {
        width: 420px;
      }
      @media screen and (max-width: 1300px) {
        width: 400px;
      }
      @media screen and (max-width: 1000px) {
        width: 380px;
      }
      @media screen and (max-width: 700px) {
        width: 300px;
      }
    }
  }
  &-card {
    &-item {
      display: flex;
      align-items: center;
      gap: 40px;
      background-color: #04182fab;
      padding: 20px 40px;
      border-radius: 15px;
      color: #b0caff;
      box-shadow: 5px 10px 18px black;
      @media screen and (max-width: 700px) {
        padding: 20px 15px;
      }
      cursor: pointer;
      transition: all 0.25s linear;
      &-wrapper {
        transition: all 0.25s linear;
        &:hover {
          transform: scale(0.9);
        }
      }
      &-img {
        img {
          width: 70px;
        }
      }
    }
    &-item-wrapper:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

// .show {
//   transform: translateX(0);
// }
