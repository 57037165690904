.footer {
  position: relative;
  z-index: 99999;
  margin-top: 100px;
  display: flex;
  gap: 50px;
  padding-bottom: 30px;
  padding-top: 50px;
  @media screen and (max-width: 700px) {
    margin-top: 50px;
    flex-direction: column;
    gap: 0;
  }
  &-desc {
    font-size: 15px;
    padding-right: 200px;
    @media screen and (max-width: 1600px) {
      padding-right: 180px;
    }
    @media screen and (max-width: 1400px) {
      padding-right: 150px;
    }
    @media screen and (max-width: 1300px) {
      padding-right: 130px;
    }
    @media screen and (max-width: 1100px) {
      padding-right: 100px;
    }
    @media screen and (max-width: 1000px) {
      padding-right: 60px;
    }
    @media screen and (max-width: 900px) {
      padding-right: 0;
    }
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
  &-left,
  &-right {
    width: 50%;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
  &-right {
    margin-top: 80px;
    @media screen and (max-width: 700px) {
      margin-top: 35px;
    }
  }
  &-logo {
    width: 200px;
    margin-bottom: 20px;
    @media screen and (max-width: 700px) {
      width: 150px;
      margin-bottom: 10px;
    }
    img {
      width: 100%;
    }
  }
  &-socials,
  &-links {
    display: flex;
    // gap: 20px;
    li {
      &:not(:last-child) {
        margin-right: 20px;
        @media screen and (max-width: 700px) {
          margin-right: 10px;
        }
      }
    }
  }
  &-socials {
    margin-bottom: 20px;
    li {
      font-size: 20px;
      @media screen and (max-width: 700px) {
        font-size: 16px;
      }
    }
  }
  &-links {
    @media screen and (max-width: 700px) {
      // gap: 10px;
      font-size: 11px;
    }
  }
  &-line {
    height: 2px;
    background: rgb(200, 32, 144);
    background: linear-gradient(
      90deg,
      rgba(106, 20, 209, 1) 0%,
      rgba(200, 32, 144, 1) 80%
    );
  }
  &-full {
    background-color: #060423;
  }
  .link-custom {
    background: rgb(2, 189, 255);
    background: linear-gradient(
      90deg,
      rgba(2, 189, 255, 1) 0%,
      rgba(84, 89, 255, 1) 100%
    );
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
}

.copyright {
  display: block;
  padding: 20px 0 40px;
  font-size: 15px;
  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
}
