.faq {
  margin-top: 100px;
  position: relative;
  z-index: 99999;
  @media screen and (max-width: 700px) {
    margin-top: 50px;
  overflow: hidden;
  }
  &-bg {
    position: absolute;
    z-index: 1;
    top: 250px;
    right: -150px;
    width: 700px;
    @media screen and (max-width: 700px) {
      top: 180px;
      width: 380px;
      right: -140px;
      // display: none;
    }
  }
  &-title {
    text-align: center;
    h5 {
      margin: 20px 0 50px;
    }
  }
  &-list {
    padding: 20px 30px;
    border-radius: 20px;
    background: rgb(4, 23, 45);
    background: linear-gradient(
      180deg,
      rgba(3, 18, 163, 0.2) 20%,
      rgba(4, 23, 45, 0) 70%
    );
    position: relative;
    z-index: 9999;
  }
}
