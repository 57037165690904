.community {
  position: relative;
  z-index: 99999;
  margin-top: 100px;
  border-radius: 20px;
  text-align: center;
  padding: 70px;
  background: rgb(92, 3, 98);
  background: linear-gradient(
    24deg,
    rgba(92, 3, 98, 0.6) 0%,
    rgba(10, 2, 115, 0.15) 80%
  );
  @media screen and (max-width: 700px) {
    padding: 25px;
    margin-top: 50px;
  }
  p {
    margin: 30px 0 30px;
    color: #b0caff;
  }
  .btn {
    font-size: 1rem;
  }
  &-btn {
    display: inline-flex;
    align-items: center;
    gap: 40px;
    @media screen and (max-width: 700px) {
      gap: 20px;
    }
  }
  &-socials {
    display: flex;
    align-items: center;
    li {
      font-size: 24px;
    @media screen and (max-width: 700px) {
      font-size: 18px;
    }
      &:not(:last-child) {
        margin-right: 20px;
        @media screen and (max-width: 700px) {
          margin-right: 10px;
        }
      }
    }
  }
}
