.why {
  margin-top: 100px;
  position: relative;
  z-index: 99999;
  // overflow: hidden;
  .container {
    position: relative;
    z-index: 999;
  }
  &-bg {
    width: 700px;
    position: absolute;
    z-index: 1;
    top: -250px;
    left: -150px;
    @media screen and (max-width: 1200px) {
      width: 400px;
    }
    @media screen and (max-width: 700px) {
      // display: none;
    }
  }
  &-title {
    text-align: center;
    background: rgb(84, 89, 255);
    background: linear-gradient(
      101deg,
      rgba(84, 89, 255, 1) 20%,
      rgba(242, 19, 129, 1) 70%
    );
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  &-card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    gap: 50px;
    @media screen and (max-width: 700px) {
      display: none;
    }
    &-mb {
      display: none;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 50px;
      gap: 50px;
      @media screen and (max-width: 700px) {
        display: flex;
      }
    }
  }
  &-card-item {
    width: 230px;
    border-radius: 20px;
    padding: 10px 30px;
    height: 200px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    gap: 30px;
    background-color: #04182fcc;
    cursor: pointer;
    transition: all 0.2s linear;
    &:hover {
      transform: scale(1.1);
    }
    @media screen and (max-width: 1200px) {
      width: 200px;
      padding: 10px 20px;
    }
    @media screen and (max-width: 1000px) {
      padding: 10px;
      gap: 20px;
    }
    @media screen and (max-width: 700px) {
      height: 170px;
    }
    p {
      text-align: center;
      line-height: 22px;
      padding: 0 20px;
      @media screen and (max-width: 700px) {
        padding: 0;
      }
    }
  }
  &-card-img {
    height: 80px;
    @media screen and (max-width: 700px) {
      height: 60px;
    }
    img {
      height: 100%;
    }
  }
}
