.popup-wrapper {
  position: fixed;
  z-index: 99999999999;
  min-width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  &.hidden {
    display: none;
  }
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  border-radius: 25px;
  background: rgb(189, 3, 165);
  background: linear-gradient(
    21deg,
    rgba(189, 3, 165, 0.8) 0%,
    rgba(18, 28, 127, 0.8) 60%
  );
  @media screen and (max-width: 1600px) {
    width: 50%;
  }
  @media screen and (max-width: 1350px) {
    width: 60%;
  }
  @media screen and (max-width: 1150px) {
    width: 70%;
  }
  @media screen and (max-width: 700px) {
    width: 80%;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  &-header {
    position: absolute;
    right: 10px;
    top: 10px;
    img {
      cursor: pointer;
      @media screen and (max-width: 700px) {
        width: 25px;
      }
    }
  }
  &-main {
    display: flex;
    gap: 50px;
    align-items: center;
    padding: 30px 50px;
    @media screen and (max-width: 850px) {
      justify-content: center;
      padding: 20px 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 35px;
    }
    &-left {
      width: 40%;
      position: relative;
      @media screen and (max-width: 1150px) {
        width: 30%;
      }
      @media screen and (max-width: 850px) {
        display: none;
      }
      img {
        width: 100%;
      }
      &-email {
        display: flex;
        align-items: center;
        background-color: #02132756;
        position: absolute;
        gap: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 8px 15px;
        border-radius: 12px;
        span {
          color: #00ffea;
        }
        img {
          width: 23px;
          cursor: pointer;
          filter: invert(17%) sepia(97%) saturate(4837%) hue-rotate(320deg) brightness(98%) contrast(94%);
        }
      }
    }
    &-right {
      text-align: center;
      h5 {
        line-height: 40px;
        @media screen and (max-width: 1600px) {
          line-height: 30px;
        }
        @media screen and (max-width: 700px) {
          line-height: 25px;
        }
      }
      p {
        margin: 15px 0 30px;
        line-height: 23px;
        @media screen and (max-width: 1600px) {
          line-height: 20px;
        }
        @media screen and (max-width: 700px) {
          line-height: 18px;
        }
      }
      .btn {
        gap: 10px;
        img {
          width: 25px;
          @media screen and (max-width: 700px) {
            width: 22px;
          }
        }
        span {
          font-size: 15px;
          @media screen and (max-width: 700px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
