// Reset
html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
del,
em,
img,
small,
strong,
b,
u,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
button,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  word-wrap: break-word;
}

html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: inherit;
  font-weight: inherit;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}

a img {
  border: none;
}

article,
aside,
details,
figure,
footer,
header,
menu,
nav,
section,
summary {
  display: block;
}

button,
a {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background: none;
  border: none;
}

@font-face {
  font-family: FontCommon;
  src: url(./fonts/Ebrima/ebrima.ttf);
  font-weight: 400;
}

@font-face {
  font-family: FontSmallTitle;
  src: url(./fonts/iCielGotham/Gotham-Medium.otf);
  font-weight: 400;
}

@font-face {
  font-family: FontTitle;
  src: url(./fonts/SegoeUI/segoeuib.ttf);
  font-weight: 400;
}
// @font-face {
//   font-family: FontCommonBold;
//   src: url(./fonts/Ebrima/ebrimabd.ttf);
//   font-weight: 600;
// }

html {
  font-size: 18px;
  word-spacing: 1px;
  scroll-behavior: smooth;
  font-family: "FontCommon", sans-serif;
  @media screen and (max-width: 1800px) {
    font-size: 17px;
  }
  @media screen and (max-width: 1700px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1400px) {
    font-size: 15px;
  }
  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}

p {
  line-height: 25px;
  @media screen and (max-width: 1100px) {
    line-height: 20px;
  }
  @media screen and (max-width: 1000px) {
    line-height: 18px;
  }
  @media screen and (max-width: 700px) {
    line-height: 17px;
  }
}

h5,
h4 {
  font-family: "FontTitle", sans-serif;
}

h5 {
  font-size: 1.8rem;
  @media screen and (max-width: 1400px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 1000px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 1.3rem;
  }
}

// Common
.container {
  max-width: 70%;
  margin: 0 auto;
  @media screen and (max-width: 1400px) {
    max-width: 75%;
  }
  @media screen and (max-width: 1250px) {
    max-width: 80%;
  }
  @media screen and (max-width: 1100px) {
    max-width: 85%;
  }
  @media screen and (max-width: 700px) {
    max-width: 90%;
  }
}

.container-min {
  max-width: 60%;
  margin: 0 auto;
  position: relative;
  z-index: 99999999;
  @media screen and (max-width: 1000px) {
    max-width: 75%;
  }
  @media screen and (max-width: 700px) {
    max-width: 85%;
  }
}

.mt-100 {
  margin-top: 150px;
}

.text-pink {
  display: block;
  color: #dd12d0;
  font-weight: bold;
  font-family: "FontsSmallTitle", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  @media screen and (max-width: 1000px) {
    font-size: 17px;
  }
  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  color: #fff;
  padding: 12px 20px;
  border-radius: 6px;
  font-size: 0.8rem;
}

.btn-primary {
  background: rgb(242, 19, 129);
  background: linear-gradient(
    -300deg,
    rgba(242, 19, 129, 1) 0%,
    rgba(229, 69, 230, 1) 100%
  );
  transition: all 0.3s linear;
  &:hover {
    opacity: 0.6;
  }
}

.ml-negative {
  margin-left: -50px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
  @media screen and (max-width: 700px) {
    gap: 25px;
  }
}

// Animation
// .reveal {
//   position: relative;
//   -ms-transform: translateY(150px);
//   -moz-transform: translateY(150px);
//   -webkit-transform: translateY(150px);
//   -o-transform: translateY(150px);
//   transform: translateY(150px);
//   opacity: 0;
//   transition: 1s all ease;
//   @media screen and (max-width: 700px) {
//     transform: translateY(50px);
//   }
// }

.reveal-top {
  position: relative;
  transform: translateY(150px);
  transition: 1s all ease;
  opacity: 0;
  &.active {
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.reveal-left {
  position: relative;
  -ms-transform: translateX(-150px);
  -moz-transform: translateX(-150px);
  -webkit-transform: translateX(-150px);
  -o-transform: translateX(-150px);
  transform: translateX(-150px);
  transition: 1s all ease;
  &.active {
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.reveal-right {
  // position: relative;
  // -ms-transform: translateX(150px);
  // -moz-transform: translateX(150px);
  // -webkit-transform: translateX(150px);
  // -o-transform: translateX(150px);
  // transform: translateX(150px);
  // transition: 1s all ease;
  // &.active {
  //   -ms-transform: translateX(0);
  //   -moz-transform: translateX(0);
  //   -webkit-transform: translateX(0);
  //   -o-transform: translateX(0);
  //   transform: translateX(0);
  // }
}

.reveal-right-1 {
  position: relative;
  -ms-transform: translateX(150px);
  -moz-transform: translateX(150px);
  -webkit-transform: translateX(150px);
  -o-transform: translateX(150px);
  transform: translateX(150px);
  transition: 0.7s all ease;
  &.active {
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
.reveal-right-2 {
  position: relative;
  -ms-transform: translateX(150px);
  -moz-transform: translateX(150px);
  -webkit-transform: translateX(150px);
  -o-transform: translateX(150px);
  transform: translateX(150px);
  transition: 1.5s all ease;
  &:hover {
    transform: scale(0.9);
  }
  &.active {
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
.reveal-right-3 {
  position: relative;
  -ms-transform: translateX(150px);
  -moz-transform: translateX(150px);
  -webkit-transform: translateX(150px);
  -o-transform: translateX(150px);
  transform: translateX(150px);
  transition: 2.3s all ease;
  &:hover {
    transform: scale(0.9);
  }
  &.active {
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
.reveal-right-4 {
  position: relative;
  -ms-transform: translateX(150px);
  -moz-transform: translateX(150px);
  -webkit-transform: translateX(150px);
  -o-transform: translateX(150px);
  transform: translateX(150px);
  transition: 3.1s all ease;
  &:hover {
    transform: scale(0.9);
  }
  &.active {
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

// .reveal.active {
//   -ms-transform: translateY(0);
//   -moz-transform: translateY(0);
//   -webkit-transform: translateY(0);
//   -o-transform: translateY(0);
//   transform: translateY(0);
//   opacity: 1;
// }

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 30s linear infinite;
  -moz-animation: rotating 30s linear infinite;
  -ms-animation: rotating 30s linear infinite;
  -o-animation: rotating 30s linear infinite;
  animation: rotating 30s linear infinite;
}

.test {
  animation: test 1s ease-out 1;
}

@keyframes test {
  from {
    opacity: 0;
    // -ms-transform: translateY(20px);
    // -moz-transform: translateY(20px);
    // -webkit-transform: translateY(20px);
    // -o-transform: translateY(20px);
    transform: translateY(150px);
  }
  to {
    opacity: 1;
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

body {
  background-color: #060423;
  // overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

// App
.app {
  background-color: #021327;
  min-height: 100vh;
  color: #fff;
  // overflow-x: hidden;
  // @media screen and (max-width: 700px) {
  // overflow-x: hidden;
  // }
}

.section-top {
  position: relative;
  z-index: 99999;
  &-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: transparent;
    background-image: linear-gradient(180deg, #02132700 0%, #021327 100%);
    opacity: 1;
  }
}
