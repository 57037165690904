.switch {
  margin-top: 100px;
  text-align: center;
  position: relative;
  z-index: 9999999;
  .container {
    position: relative;
    z-index: 999999;
  }
  @media screen and (max-width: 700px) {
    margin-top: 50px;
  }
  &-title {
    display: inline-flex;
    gap: 20px;
    background-color: #5674b93d;
    padding: 12px 20px;
    border-radius: 30px;
    @media screen and (max-width: 700px) {
      padding: 10px 17px;
    }
  }
  button {
    color: #fff;
    padding: 10px 12px;
    border-radius: 20px;
  }
  button.active {
    background-color: #ff29d8;
  }
  &-card {
    margin-top: 100px;
    padding: 30px 50px;
    border-radius: 30px;
    display: flex;
    gap: 50px;
    align-items: center;
    background-color: #04182fcc;
    // animation: moveAnimation 1s linear infinite;
    // height: 100%;
    @media screen and (max-width: 1200px) {
      gap: 30px;
    }
    @media screen and (max-width: 700px) {
      margin-top: 50px;
      padding: 25px 30px;
    }

    &-content {
      width: 60%;
      text-align: left;
      padding-left: 100px;
      @media screen and (max-width: 1700px) {
        padding-left: 70px;
      }
      @media screen and (max-width: 1500px) {
        padding-left: 50px;
      }
      @media screen and (max-width: 1200px) {
        padding-left: 20px;
      }
      @media screen and (max-width: 700px) {
        width: 100%;
        padding-left: 0;
      }
      .show {
        opacity: 1;
      }
      .hidden {
        opacity: 0;
      }
      p {
        margin: 40px 0;
        color: #b0caff;
        @media screen and (max-width: 1400px) {
          margin: 30px 0;
        }
        @media screen and (max-width: 1200px) {
          margin: 20px 0;
        }
        span {
          display: block;
          margin-top: 20px;
          @media screen and (max-width: 1200px) {
            margin-top: 15px;
          }
          @media screen and (max-width: 1000px) {
            margin-top: 10px;
          }
        }
      }
      a {
        color: #ff29d8;
      }
    }
    &-img {
      width: 350px;
      @media screen and (max-width: 1200px) {
        width: 280px;
      }
      @media screen and (max-width: 1000px) {
        width: 250px;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
      img {
        width: 100%;
      }
    }
  }
}

.fading {
  animation: fading 0.4s ease-out 1;
}

@keyframes fading {
  from {
    opacity: 0;
    -ms-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
